/** @jsx jsx */
import { jsx, Box, Container } from "theme-ui";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Hero from "../components/Hero";
import Helmet from "react-helmet";
import BreadCrumbs from "../components/BreadCrumbs";
import { useSitePages } from "../hooks/useSitePages";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import { getUrlFromSlug } from "../utils/utils";
import ContentHubPager from "../components/ContentHubPager";
import { useAllContentfulContentHubPages } from "../hooks/useAllContentfulContentHubPages";
import { getLocalizedUrlAndTitleFromSlug } from "../utils/utils";
import { useContentHubQueryParamFiltering } from "../hooks/useContentHubQueryParamFiltering";
import { createImageUrl } from "../utils/utils";

const ContentHubThemePageTemplate = ({ data, pageContext }) => {
  const nodes = useSitePages();
  const {
    pageType,
    title,
    navigationTitle,
    slug,
    hero,
    metaDescription,
    metaTitle,
    hidden,
    noIndex,
  } = data.contentfulContentHubThemePage;

  const { language, paths, theme } = pageContext;
  const allContentHubContentPages = useAllContentfulContentHubPages();
  const allPages = allContentHubContentPages[language].filter(
    ({ node }) => node.theme && node.theme.theme === theme
  );
  const { resultPages } = useContentHubQueryParamFiltering({
    allPages,
    theme,
  });

  const { defaultDescription, siteUrl, defaultImage } = useSiteMetadata();

  let metadataTitle = metaTitle || title;

  const metaImage =
    (hero &&
      hero.image &&
      hero.image.gatsbyImageData &&
      createImageUrl(hero.image)) ||
    siteUrl + defaultImage;

  const { title: localizedTitle, slug: localizedSlug } =
    getLocalizedUrlAndTitleFromSlug("/sisaltostudio", language, nodes);

  const metadataDescription =
    metaDescription &&
    metaDescription.internal &&
    metaDescription.internal.content
      ? metaDescription.internal.content
      : defaultDescription;

  return (
    <Layout pageContext={pageContext} pageType={pageType}>
      <SEO
        lang={language}
        title={title}
        description={metadataDescription}
        image={metaImage}
        paths={paths}
        hidden={hidden}
        noIndex={noIndex}
        metadataTitle={metadataTitle}
      />
      <Container>
        <BreadCrumbs
          slug={slug}
          navigationTitle={navigationTitle ? navigationTitle : title}
          parentPage={{
            slug: localizedSlug,
            navigationTitle: localizedTitle,
          }}
          language={language}
        />
      </Container>
      <Container id="content">
        {hero && (
          <Box sx={{ mx: [-3, -4, 0], mb: [4, null, 6] }}>
            <Hero {...hero} />
          </Box>
        )}
      </Container>
      <Container sx={{ mb: [4, null, 6] }}>
        <ContentHubPager
          title={theme}
          contentHubPages={resultPages}
          sx={{ my: "2em" }}
          language={language}
          noHeroOnPage={hero ? false : true}
        />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!) {
    contentfulContentHubThemePage(id: { eq: $id }) {
      id
      contentful_id
      __typename
      pageType
      title
      navigationTitle
      slug
      node_locale
      metaDescription {
        internal {
          content
        }
      }
      hero {
        title
        type
        description
        node_locale
        image {
          gatsbyImageData(quality: 85, width: 1080)
        }
        linkText
        link {
          ... on ContentfulPage {
            slug
          }
          ... on ContentfulProductPage {
            slug
          }
        }
        analyticsAction
        analyticsId
        target
      }
      hidden
      noIndex
    }
  }
`;

export default ContentHubThemePageTemplate;
